.dialog {
  background-size: cover!important;
  background-position: center;
  background: unset;
  > .dialogInner {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(30,30,30,0.25) 0%, rgba(30,30,30,1) 50%, rgba(30,30,30,1) 100%);
    backdrop-filter: blur(15px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.subtitle {
  /** Typography variant Sub 3 */
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
}

// .spotifyButton {
//   background-color: #1ed760 !important;
//   &:hover {
//     background-color: #1fdf64 !important;
//   }
//   &:focus {
//     background-color: #169c46 !important;
//   }
// }

// .appleButton {
//   background-color: #ff3258 !important;
//   &:hover {
//     background-color: #f94364 !important;
//   }
//   &:focus {
//     background-color: #f92148 !important;
//   }
// }

// .appleSecondaryButton {
//   color: var(--mui-palette-text-secondary);
//   border-color: var(--mui-palette-text-secondary);
//   &:hover {
//     border-color: var(--mui-palette-text-primary);
//     background-color: var(--mui-palette-white-light);
//   }
//   &:focus {
//     border-color: var(--mui-palette-text-primary);
//   }
// }
