$img-margin: 20%;
$mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
$block-bg-colour: #1c1d24;
$linkbox-max-width:  580px;

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background-color: #111111;
  background-size: cover;
  align-items: center;
  justify-content: center;
  .containerInner {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .content {
    position: relative;
    width: 100%;
    max-width: 480px;
    padding-top: 0px;
    padding-bottom: 5px;
    position: relative;

    margin-left: auto;
    margin-right: auto;

    overflow-y: auto;
  }

  .main_image {
    top: 0;
    transition: height 0.1s linear;
    width: 100%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content:center;

    .titlearea {
      position: relative;
      z-index: 2;
      margin: 0px 10px;
      text-align: center;
      text-shadow: #000 1px 0 10px;
      transition: max-height 0.3s linear;
      overflow: hidden;

      z-index: 10;

      .title {
        color: white;
        margin-bottom: 8px;
      }

      .subtitle {
        font-weight: 300;
        color: #bbbac3;
      }
    }

    .videoPlayerContainer {
      position: relative;
      width: 100%;
      height: 350px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .videoPlayerButtonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
    }


    &.main_imageShowTracks {
      padding-top: 30px;
      justify-content: center;
      &::after {
        background: transparent;
      }
      .titlearea {
        text-shadow: none;
        text-align: left;
        .title {
          font-size: 15px;
          line-height: 20px;
        }
        .subtitle {
          font-size: 12px;
          line-height: 20px;
        }
      }
      
    }
    img {
      max-width: 60%;
      border-radius: 4px;
    }
  }

  .videoPlayerIframe {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 10px;
    aspect-ratio: 16/9;
    max-width: 450px;
    max-height: 300px;
    margin: auto;
    &.videoPlayerIframePositioned {
      // position: relative;
      max-width: 300px;
      max-height: 200px;
    }
  }

  .bgImage {
    background-size: contain;
    -webkit-mask-image: linear-gradient(to top, transparent 15%, black 75%);
    mask-image: linear-gradient(to top, transparent 15%, black 75%);
  }

  .audioPlayerIframe {
    border: 0;
    width: calc(100% - 0.5rem);
    height: 80px;
    margin-bottom: 0.25rem;
  }

  .socials {
    display: flex;
    gap: 0.05rem;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.33rem;
    svg {
      width: 23px;
      height: 23px;
      color: #ffffff;
    }
  }

  &.desktop {
    overflow: auto;
    height: 100vh;

    .content {
      max-width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(100deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
    }

    .container {
      background-size: cover;
    }

    .socials {
      margin-top: 20px;
    }

    .audioPlayerIframe {
      margin-top: 35px;
      max-width: 60%;
    }

    .videoPlayerContainer {
      margin: 25px 0px;
      flex-grow: 1;
    }

    .socials {
      padding: 0 10px;

      svg {
        width: 42px;
        height: 42px;
      }
    }
    .linkarea {
      z-index: 1;
      justify-content: center;

      .linkbox {
        max-width: $linkbox-max-width;
        padding: 10px 14px;
        margin-top: 8px;

        .link_destination {
          margin-top: 4px;
          padding-left: 15px;
        }

        .logo {
          height: 40px;
          width: 50px;
        }

        .cta {
          min-width: 120px;
          height: 45px;
          color: #ffffff;
        }

        &:first-child {
          margin-top: 0;
        }
        
        // background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .main_image::after {
      display: none;
    }
    .videoPlayerIframePositioned {
      margin: 25px auto;
    }
  }

  &:not(.desktop) {
    .content {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */


      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .linkarea {
    padding: 0px 4px 30px 4px;
    width: 100%;
    height: 100%;
  }

  .linkbox {
    display: block;
    background-color: $block-bg-colour;
    border-radius: 4px;
    width: 100%;

    padding: 12px;
    margin-top: 6px;

    &:first-child {
      margin-top: 1px;
    }

    .link_destination {
      font-weight: 300;
      font-size: 17px;
      color: #f6f9fa;
    }

    .logo {
      height: 32px;
      width: 52px;

      span {
        margin: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        padding-right: 10px;
      }
    }

    .cta {
      font-size: 0.9rem;
      font-weight: 600;
      padding: 0.25rem 1rem;
      line-height: 120%;
    }
  }
}

.artwork {
  min-height: 150px;
  transition: all 0.3s linear;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 65%;
    aspect-ratio: 1/1;
    // TODO: Check this for compatibility
  }
}

.topBarTitle {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  background-color: #444444;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: opacity 0.2s linear;
}

.oneLineTruncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.countdownContainer {
  position: relative;
  max-width: $linkbox-max-width;
  align-items: center;
  margin: 5px 0px 15px 0px;
  gap: 25px;
}

.countdown {
  contain: inline-size;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 25px;
  margin-top: 10px;
  width: 100%;
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      &:last-child {
        color: #999;
      }
    }
  }
  @media (max-height: 600px) {
    display: none;
  }
}

.countdownRelease {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(30deg, transparent 30%, rgba(100, 100, 100, 0.3) 32%, transparent 33%), radial-gradient(circle at 60% 40%, transparent 15px, rgba(70, 70, 70, 0.25) 17%, transparent 20%) rgba(255, 255, 255, 0.075);
  box-shadow: 0 0 2px rgba(100, 100, 100, 0.6);
  transform: rotate(var(--rt));
    clip-path: polygon(1% 0%, 19% 1%, 30% 0%, 38% 0%, 48% 1%, 58% 3%, 62% 3%, 79% 3%, 73% 1%, 84% 0%, 87% 0%, 91% 4%, 92% 2%, 99% 0%, 96% 5%, 96% 20%, 100% 34%, 100% 46%, 99% 54%, 95% 57%, 87% 69%, 100% 77%, 100% 82%, 100% 89%, 100% 96%, 90% 99%, 76% 100%, 71% 97%, 63% 100%, 54% 99%, 51% 100%, 37% 98%, 34% 100%, 21% 99%, 17% 100%, 14% 98%, 10% 100%, 3% 98%, 3% 100%, 0% 85%, 4% 80%, 0% 74%, 4% 55%, 6% 45%, 3% 40%, 0% 34%, 6% 25%, 4% 16%, 1% 13%);
  width: 100%;
  color: #e3e3e3;
  padding: 4px;
  min-height: 40px;
}

.confetti {
  position: absolute;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
}

.immersiveIcon {
  width: 20px;
  height: 20px;
  z-index: 100;
  margin-left: 6px;
}