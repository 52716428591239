.circleIconBordered {
    border: 1px solid var(--mui-palette-text-secondary)!important;
}

.circleIconActive {
    border-color: var(--mui-palette-primary-light)!important;
}

.circleIconSelected {
    color: var(--mui-palette-text-primary);
    background-color: var(--mui-palette-primary-dark);
}

.circleIconSolid {
    background: var(--mui-palette-background-paper);
    &:hover {
        background: var(--mui-palette-text-disabled);
    }
}

.circleIconDone {
    background: var(--mui-palette-success-light);
    color: var(--mui-palette-success-dark);
    pointer-events: none;
    &:hover {
        background: var(--mui-palette-success-light);
    }
}